const awsAuth = {
    Auth: {

        identityPoolId: 'eu-west-2:a441e4d1-e08d-4c72-888b-9f104171bdaf',
        // REQUIRED - Amazon Cognito Region
        region: 'eu-west-2',
        //  Amazon Cognito User Pool ID
        userPoolId: 'eu-west-2_zdj9pzcIl',

        // Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3467kqrkf5k26e71bcvj071648',


        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

    }
};

export default awsAuth