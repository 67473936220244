import React from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class   ExcelDownload extends React.Component {

    render() {
        const {
            dataSet,
            title,
            fileName
        } = this.props;

        return (
            <ExcelFile element={<button>Download Data</button>} filename={fileName}>
                <ExcelSheet dataSet={dataSet} name={title} />
            </ExcelFile>
        );
    }
};

export default ExcelDownload