import React, {forwardRef} from 'react';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Search from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import MaterialTable from "material-table";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Grid from '@material-ui/core/Grid';
import {TextMaskMonth, getMonth } from '../GridUtil'
import ExcelDownload  from '../export/Excel'


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    table: {
        minWidth: 650,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

async function getData(Api, month) {
    let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
    };
    let apiName = 'bookkeeping-api';
    let path = '/liststatements/' + month;

    return await Api.get(apiName, path, myInit);
}

async function updateData(Api, record) {
    let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        body: {'record' : record}
    };
    let apiName = 'bookkeeping-api';
    let path = '/updatestatements';

    return await Api.post(apiName, path, myInit);
}

async function addData(Api, record) {
    let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        body: {'record': record}
    };
    let apiName = 'bookkeeping-api';
    let path = '/updatestatements';

    return await Api.put(apiName, path, myInit);
}

async function deleteData(Api, record) {
    let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        body: {'record' : record}
    };
    let apiName = 'bookkeeping-api';
    let path = '/updatestatements';

    return await Api.del(apiName, path, myInit);
}

function getExportHeders(data)
{
    const headers = ['Date','References']
    const expense_types = new Set();
    for (var x in data) {
        const element = data[x];
        if (element['expense_type'])
            expense_types.add(element['expense_type'])
    }
    return headers.concat(Array.from(expense_types.values()));
}

function getDefaultStyle(value){
    const borderType = {style: "medium", color : { auto: 1}}
    return {
        value: value.toString() ,
        style : {border : {top: borderType, bottom : borderType, left: borderType, right: borderType }}
    }


}
function getDataRow(headers,row)
{
    const dataRow = [getDefaultStyle(row['transaction_date']),
        getDefaultStyle(row['description'])]
    for (var x in headers)
    {
        if (x < 2) continue;
        const header = headers[x];
        const value = row['expense_type'] === header ? row['amount'] : ""
        dataRow.push(getDefaultStyle(value));
    }
    return dataRow;

}

function getDataSet(data){
    const headers = getExportHeders(data);
    const dataSet = [];
    const bankExpenses = {}
    bankExpenses['columns'] = headers;
    bankExpenses['data'] = []
    const cashExpenses = {}
    cashExpenses['columns'] = headers;
    cashExpenses['data'] = []
    for (var x in data)
    {
        const element = data[x]
        const expense = element['payment_type'] === 'Cash' ? cashExpenses : bankExpenses;
        if (element['expense_type'])
         expense['data'].push(getDataRow(headers, element));
    }
    dataSet.push({'columns':['Bank'], 'data' : []})
    dataSet.push(bankExpenses)
    dataSet.push({'columns':['Cash'], 'data' : []})
    dataSet.push(cashExpenses)
    return dataSet
}

class Expenses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            monthYear: '',
            textmask : '  -  '
        }

    }

    update = function(Api, newData, oldData)
    {
        const rows = this.state.rows;
        return updateData(Api, newData).then ( data =>{
            const index = rows.indexOf(oldData);
            rows[index] = newData;
            this.setState({ rows });
            }
        ).catch( err=> alert(err))

    }

    delete = function(Api, data)
    {
        let rows = this.state.rows;
        return deleteData(Api, data).then ( data =>{
                const index = rows.indexOf(data);
                rows.splice(index, 1);
                this.setState({ rows });
            }
        ).catch( err=> alert(err))

    }

    add = function(Api, data)
    {
        const rows = this.state.rows;
        if (data['amount'] > 0)  data['amount'] = -data['amount']
        data['payment_type'] = 'Cash'
        delete data['tableData']
        return addData(Api, data).then ( data =>{
                rows.push(data);
                this.setState({ rows });
            }
        ).catch( err=> alert(err))

    }

    lookup = function (Api) {
        if (this.state.monthYear.length === 0) return;
        getData(Api, this.state.monthYear).then(data => {
            const expenses = []
            for (var x in data) {
                const element = data[x];
                element.amount = Number(element.amount)
                if (element.amount < 0) expenses.push(element)
            }
            this.setState({rows: expenses})
        })
    };


    updateMonth = (name) => event => {
        const date = event.target.value
        const monthYear = getMonth(date)
        this.setState({monthYear: monthYear, [name]: date});
    };

    render() {

        const {
            rows,
            textmask,
            monthYear
        } = this.state;
        const {
            Api
        } = this.props;

        const fileName = monthYear + "_expenses"

        return (
            <Paper className={useStyles.root}>
                <div>
                    <Grid container spacing={3} alignItems="flex-end">
                        <Grid item>
                            <Button variant="contained" color="secondary" className={useStyles.button}
                                    onClick={() => this.lookup(Api)}>
                                Retrieve expenses
                                <SearchIcon className={useStyles.rightIcon}/>
                            </Button>
                        </Grid>
                        <Grid item>
                            <TextField
                                className={useStyles.formControl}
                                label="Month in yy-mm format"
                                value={textmask}
                                onChange={this.updateMonth('textmask')}
                                id="formatted-numberformat-input"
                                InputProps={{
                                    inputComponent: TextMaskMonth,
                                }}
                            />

                        </Grid>
                        <Grid item>
                            <ExcelDownload title="Expenses" dataSet={getDataSet(rows)} fileName={fileName}/>
                        </Grid>
                    </Grid>
                    <MaterialTable
                        title="Expense export"
                        icons={tableIcons}
                        columns={[
                            {title: 'Transaction Date', field: 'transaction_date',editable: 'onAdd'},
                            {title: 'Description', field: 'description',editable: 'onAdd'},
                            {title: 'Type', field: 'payment_type',editable: 'never'},
                            {
                                title: 'Amount',
                                field: 'amount',
                                type: 'numeric',
                                editable: 'onAdd'
                            },
                            {
                                title: 'Expense Type',
                                field: 'expense_type',
                                lookup: {
                                    'Insurance': 'Insurance',
                                    'Purchases': 'Purchases',
                                    'Fees': 'Fees',
                                    'Bank Charges': 'Bank Charges',
                                    'Wages/Tax/NI/Pension': 'Wages/Tax/NI/Pension',
                                    'Household': 'Household',
                                    'VAT': 'VAT',
                                    'Motor Vehicle/ Travel': 'Motor Vehicle/ Travel',
                                    'Accountancy': 'Accountancy',
                                    'Advertising': 'Advertising',
                                    'Pension': 'Pension',
                                    'Relay Charge': 'Relay Charge',
                                    'Rent and Rates': 'Rent and Rates',
                                    'Telephone/Computer/Internet': 'Telephone/Computer/Internet',
                                    'Heating/ Water/ Electricity': 'Heating/ Water/ Electricity',
                                    'Sundry': 'Sundry',
                                    'Rubbish': 'Rubbish',
                                    'Stationery': 'Stationery',
                                    'Drawdown' : 'Drawdown'
                        },
                            }
                        ]}
                        data={rows}
                        options={{
                            filtering: true,
                            sorting: true,
                            pageSize: 50
                        }}
                        editable={{
                            onRowAdd: newData => this.add(Api, newData),
                            onRowUpdate: (newData, oldData) => this.update(Api,newData, oldData),
                            onRowDelete: oldData => this.delete(Api, oldData),
                        }}
                    />
                </div>

            </Paper>
        );
    }
}

export default Expenses;