import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MaskedInput from 'react-text-mask';

const headRows = [
    {
        id: "transaction_date",
        numeric: false,
        disablePadding: true,
        label: "Transaction date"
    },
    {id: "description", numeric: false, disablePadding: false, label: "Description"},
    {id: "payment_type", numeric: false, disablePadding: false, label: "Type"},
    {id: "amount", numeric: true, disablePadding: false, label: "Amount"}
];

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy) {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function onClick (e) {
    const val = e.target.value
    if (val === '__-__')
    {
        e.target.setSelectionRange(0,0)
    }
}

export function TextMaskMonth(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            onClick={(e)=> onClick(e)}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
            showMask
        />
    );
}

TextMaskMonth.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

 function pad (num, size) {
    var s = "00" + num;
    return s.substr(s.length - size);
};


export   function  getMonth (date)  {
    return '20'+date;
};

export function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
        headRows
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? "right" : "left"}
                        padding={row.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTable(props) {

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("date");
    const {
        rows,
        headRows,
        classes
    } = props;


    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }


    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>

                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headRows={headRows}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map(row => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.transaction_date + row.description + row.payment_type + row.amount}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.transaction_date}
                                            </TableCell>
                                            <TableCell align="left">{row.description}</TableCell>
                                            <TableCell align="left">{row.payment_type}</TableCell>
                                            <TableCell align="right">{row.amount}</TableCell>

                                        </TableRow>
                                    );
                                })}

                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    headRows: PropTypes.array.isRequired
};

