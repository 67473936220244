import React, {forwardRef} from 'react';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Search from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import MaterialTable from "material-table";
import Paper from '@material-ui/core/Paper';
import {TextMaskMonth, getMonth } from '../GridUtil'
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    table: {
        minWidth: 650,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

async function getData(Api, month) {
    let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
    };
    let apiName = 'bookkeeping-api';
    let path = '/liststatements/' + month;

    return await Api.get(apiName, path, myInit);
}


class SimpleTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            monthYear: '',
            textmask : '  -  '
        }

    }

    lookup = function (Api) {
        if (this.state.monthYear.length === 0) return;
        getData(Api, this.state.monthYear).then(data => {
            for (var x in data) {
                const element = data[x];
                element.amount = Number(element.amount)
            }
            this.setState({rows: data})
        })
    };

    updateMonth = (name) => event => {
        const date = event.target.value
        const monthYear = getMonth(date)
        this.setState({monthYear: monthYear, [name]: date});
    };



    render() {

        const {
            rows,
            textmask
        } = this.state;
        const {
            Api
        } = this.props;

        return (
            <Paper className={useStyles.root}>
                <div>
                    <Grid container spacing={3} alignItems="flex-end">
                        <Grid item>
                            <Button variant="contained" color="secondary" className={useStyles.button}
                                    onClick={() => this.lookup(Api)}>
                                Retrieve statement
                                <SearchIcon className={useStyles.rightIcon}/>
                            </Button>
                        </Grid>
                        <Grid item>
                            <TextField
                                className={useStyles.formControl}
                                label="Month in yy-mm format"
                                value={textmask}
                                onChange={this.updateMonth('textmask')}
                                id="formatted-numberformat-input"
                                InputProps={{
                                    inputComponent: TextMaskMonth,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <MaterialTable
                        title="Statement"
                        icons={tableIcons}
                        columns={[
                            {title: 'Transaction Date', field: 'transaction_date'},
                            {title: 'Description', field: 'description'},
                            {title: 'Type', field: 'payment_type'},
                            {
                                title: 'Amount',
                                field: 'amount',
                                type: 'numeric'
                            },
                        ]}
                        data={rows}
                        options={{
                            filtering: true,
                            sorting: true,
                            pageSize: 25
                        }}
                    />
                </div>

            </Paper>
        );
    }
}

export default SimpleTable;