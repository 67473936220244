import React from 'react';
import 'typeface-roboto';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Amplify, {API} from 'aws-amplify';
import {withAuthenticator} from 'aws-amplify-react';
import authConfig from './auth/awsconfig'
import apiConfig from './api/awsconfig'
import Menu from './apps/Menu'
const config = {...authConfig, ...apiConfig};

//window.LOG_LEVEL='DEBUG'

Amplify.configure(config);


class App extends React.Component {
    constructor() {
        super();
        this.state = {};
        this._validAuthStates = ['signedIn'];

    }

    render() {
        return (
            <div className="App">
                <Menu title ='Accounting tools' Api={API}/>
            </div>
        );
    }
}


export default withAuthenticator(App, {includeGreetings: true});