import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Display from './statement/Display'
import Expenses from './statement/Expenses'

const Content = (props) => {
    const {
        Api,
        className
    } = props;

    return (
            <div className={className}>

            <Switch>
                <Route exact path='/'  render={(props) => <Display {...props} Api={Api} /> }/>
                <Route path='/statements' render={(props) => <Display {...props} Api={Api} /> }/>
                <Route path='/expenses' render={(props) => <Expenses {...props} Api={Api} /> }/>
            </Switch>

            </div>
    )
};

export default Content