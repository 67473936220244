const apiConfig = {
    API: {
        endpoints: [
            {
                name: "bookkeeping-api",
                endpoint: "https://tqii7zsffa.execute-api.eu-west-2.amazonaws.com/dev",
                region: "eu-west-2"
            }
        ]

    }
};

export default apiConfig